import { Component, OnInit, OnChanges , Input, TemplateRef, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { links, authenticatedLinks } from 'src/app/mockup/youngLinks'
import { Step } from '../../modals/login-modal/login-modal.component';
import { MatchYoung } from 'src/app/model/match-young';
import { MatchOwner } from 'src/app/model/match-owner';
import { MatchEmployee } from 'src/app/model/match-employee.class';
export interface Link {
  url: string,
  name: string
}
interface BackgroundMaskConfig {
  size: string,
  position: string
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, AfterViewInit, OnChanges, AfterViewInit {
  @ViewChild('header') header:ElementRef;

  @Input() isInverse: boolean = true
  @Input() isFixed: boolean = false
  isOpen?: boolean
  @Input() links: Link[] = []
  isCollapsed = true;
  @Input() buttonLinks: Link[] = []
  @Input() isAuthenticated?: boolean
  @Input() isCompany?: boolean
  @Input() backgroundMask?: string
  @Input() backgroundMaskConfig?: BackgroundMaskConfig;
  @Input() isYoungPage?: boolean
  
  isSideMenuOpen?: boolean
  user?: MatchYoung | MatchOwner | MatchEmployee
  isBackgroundColored: boolean = false
  isLoginOpen?: boolean
  isUpside: boolean
  isYoung?: boolean
  initialStep: Step = 'selection'
  
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.onAuthCheck()
  }

  ngAfterViewInit() {
    const isLogin = this.router.url.indexOf('login')
    if (isLogin !== -1) {
      this.onShowLoginModal()
    }
  }
  
  ngOnChanges(): void {
    this.onAuthCheck()
    this.onHeaderChange()
  }

  onAuthCheck(){
    this.isAuthenticated = this.authenticationService.isAuthenticated()
    if (this.isAuthenticated) {
      this.getUser()
    }
    this.isYoung = this.authenticationService.verifyRoles('Young')
  }

  isProfilePage() {
    return this.router.url.indexOf('perfil')
  }

  onHeaderChange(){
    const screen = window.innerWidth;
    if (screen <= 1200) {
      this.onMobileBackgroundShow()
    }
    let prevScrollpos = window.pageYOffset;
    document.addEventListener('scroll', () => {
      let currentScrollPos = window.pageYOffset;
      this.onHeaderHide(prevScrollpos, currentScrollPos)
      prevScrollpos = currentScrollPos;
      if (screen <= 1200) {
        this.onMobileBackgroundShow()
      } else {
        this.onDesktopBackgroundShow(currentScrollPos)
      }
    });
    if(this.links.length === 0) {
      this.getHeaderLinks()
    }
  }

  getHeaderLinks() {
    if (!this.isAuthenticated && !this.isCompany){
      this.links = links
    }
    if (this.isAuthenticated && !this.isCompany && this.isYoung) {
      this.links = authenticatedLinks
    }
    else {
      this.links = links
    }
  }

  scrollTo(elementId: string) {
    const element = document.getElementById(elementId)
    element?.scrollIntoView({behavior: 'smooth'})
  }
  
  onDesktopBackgroundShow(currentScrollPos) {
    if(currentScrollPos > 120) {
      this.isBackgroundColored = true
    } else {
      this.isBackgroundColored = false
      this.header.nativeElement.classList.remove('fixed-background')
      if(this.isInverse) {
        this.header.nativeElement.classList.add('inverse');
      }
    }
  }

  onMobileBackgroundShow(){
    this.isBackgroundColored = true
    if(this.header) {
      this.header.nativeElement.classList.add('fixed-background')
    }
  }

  onHeaderHide(prevScrollpos, currentScrollPos) {
    if(this.header) {
      if (prevScrollpos > currentScrollPos) {
        this.header.nativeElement.style.top = "0";
      } else {
        this.header.nativeElement.style.top = "-120px";
        this.header.nativeElement.classList.remove('inverse');
        this.header.nativeElement.classList.add('fixed-background')
      }
    }
  }

  redirectTo(link: string) {
    this.router.navigate([link])
  }

  onButtonStyle(isWhite):boolean {
    if (isWhite) {
      return (
        !this.isInverse || this.isBackgroundColored || !!this.backgroundMaskConfig
      )
    } else {
      return (
        (!this.backgroundMaskConfig && !this.isBackgroundColored) || this.isInverse
      )
    }
  }

  onShowLoginModal(initialStep?: Step) {
    if(initialStep){
      this.initialStep = initialStep
    } else {
      this.initialStep = 'selection'
    }
    this.isLoginOpen = true
  }

  closeModal() {
    this.isLoginOpen = false
  }

  checkUrlLink(link: string) {
    return (this.router.url === link) ? ({'color': '#f8b746'}) : {}
  }


  getUser():void {
    this.user = this.authenticationService.currentUserValue
  }

  logout = (): void => {
    this.authenticationService.logout(true)
  }

  onUpsideEvent(isUpside: boolean) {
    this.isUpside = isUpside
  }

}
