import { MatchYoung } from '../../model/match-young';
import { MatchOwner } from '../../model/match-owner';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatchEmployee } from 'src/app/model/match-employee.class';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor (private http: HttpClient) { }

  // Obter dados do perfil
  getPerfil(user?: MatchEmployee | MatchOwner | MatchYoung | undefined) {
    return this.http.post<any>(`${environment.auth_url_api}user/perfil`, {user});
  }

  getUserByToken(token: any){
    return this.http.post<any>(`${environment.url_api}user/token`, {...token})
  }

  registerYoung(user: MatchYoung) {
    return this.http.post<any>(`${environment.url_api}user/young/register`, {user})
  }

  getYoungProfile() {
    return this.http.get<any>(`${environment.auth_url_api}users/young`)
  }

  getUserDetail(token?: string, isConfirmEmail?: boolean,  user_guid?: string): Observable<{user: MatchEmployee | MatchOwner | MatchYoung}> {
    let obj;
    if(token) {
      obj = {
        token: token,
        isConfirmEmail
      }
    }
    else {
      obj = {
        user_guid
      }
    }
    return this.http.post<{user: MatchEmployee | MatchOwner | MatchYoung}>(`${environment.url_api}user/detail`, obj);
  }

  updateYoung(user: any) {
    return this.http.post<any>(`${environment.auth_url_api}user/young/profile/update`, {...user})
  }

  // Atualizar perfil
  updatePerfil(user: any) {
    return this.http.post<any>(`${environment.auth_url_api}users/update`, {...user});
  }

  uploadFiles(file: File | null) {
    let reqBody = new FormData;
    if (file) {
      reqBody.append('file', file, file.name);
    }

    return this.http.post<any>(`${environment.url_api}upload`, reqBody);
  }

  public changePassword(body: any, headers: HttpHeaders) {
    return this.http.post(`${environment.url_api}password`, {...body}, {
      headers
    });
  }

  public newPassword(requestBody: object): Observable<any> {
    return this.http.post(`${environment.auth_url_api}new-password`, {requestBody});
  }

}
