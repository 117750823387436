import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatchEmployee } from 'src/app/model/match-employee.class';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NgoService {

  constructor(private http: HttpClient) { }

  getNgos(filter?: string, isAlphabeticOrder?: boolean, isDateOrder?: boolean, currentPage: number = 1) {
    let query = `?filter=${filter}&currentPage=${currentPage}&isDateOrder=${isDateOrder}&isAlphabeticOrder=${isAlphabeticOrder}`;
    return this.http.get<any>(`${environment.auth_url_api}ngos${query}`);
  }

  getNgoUnities(id_match_ngo: number) {
    return this.http.get<any>(`${environment.url_api}ngo/unities/${id_match_ngo}`);
  }

  getNgoList() {
    return this.http.get<any>(`${environment.url_api}ngos/list`);
  }

  getNgo(id_match_ngo: number) {
    return this.http.get<any>(`${environment.auth_url_api}ngo/${id_match_ngo}`);
  }

  update(id_match_ngo: number, body: any) {
    return this.http.put<any>(`${environment.auth_url_api}ngo/${id_match_ngo}`, {...body});
  }

  createNgo(body: any) {
    return this.http.post<any>(`${environment.auth_url_api}ngo`, {...body});
  }
}
