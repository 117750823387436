import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { setTheme } from 'ngx-bootstrap/utils';

import { StorageService } from 'src/app/helper/storage';
import { ModalService } from 'src/app/services/modal/modal.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { DataLayerService } from './services/google-tagmanager/data-layer.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'nau-match';
  isSnackBarOpen?: boolean
  isOpen?: boolean
  _subscription: Subscription

  constructor(
    public loadingService: LoadingService,
    private router: Router,
    private dataLayerService: DataLayerService,
    private cdRef: ChangeDetectorRef,
    private storageService: StorageService,
    private modalService: ModalService,
  ) {
    setTheme('bs4');
    this._subscription = modalService.isAuthModalOpenChange.subscribe((value) => {
      this.isOpen = value;
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.dataLayerService.logPageView(event.url)
      }
    });

  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {

    if (this.getCookiesAcception() === null || this.getCookiesAcception() === '' || this.getCookiesAcception() === undefined) {
      this.isSnackBarOpen = true
    } else {
      this.isSnackBarOpen = false
    }
  }

  getCookiesAcception() {
    return this.storageService.get('accepted-cookies')
  }

  changeCookiesPermission(isOpen: boolean, changeCookies?: boolean) {
    if (!!changeCookies) {
      this.modalService.change(this.isOpen)
    }
    this.isOpen = false
  }

  acceptCookies(hasAccept?: boolean) {
    this.storageService.set(
      'accepted-cookies', hasAccept
    )
    this.isSnackBarOpen = false
  }


}
