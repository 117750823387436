type idRoles = 1 | 2 | 3 | 4 | 5 | 6 | 7
type userRoles = 'Young' | 'Owner' | 'Employee-1' |  'Employee-2' | 'Employee-3' | 'Free' | 'Admin'
interface Roles {
  id_role: idRoles
  roles: userRoles
}
export class User {
    // Atributos das tabelas match_owners, match_employees e match_youngs
    photo?: string;
    first_name?: string;
    last_name?: string;
    cpf?: string;
    email?: string;
    phone_number?: string;
    active?: boolean;
    date_created?: string;
    date_updated?: string;
    id_role?: idRoles;
    role?: userRoles
    // Atributos de autenticação
    token?: string;
    id_profile?: number;
    id_user?: number;
    password ?: string;
    creation_date: string;
}


