import { ProgressBarModule } from './components/elements/progress-bar/progress-bar.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { JwtInterceptor } from './helper/jwt.interceptor';
import { ErrorInterceptor } from './helper/error.interceptor';
import { SnackbarModule as NgxSnackBar } from 'ngx-snackbar';
import { CookieService } from 'ngx-cookie-service';
import { ActionModalModule } from './components/modals/action-modal/action-modal.module';
import { SnackbarModule } from './components/snackbar/snackbar.module';

import ptBr from '@angular/common/locales/pt';
import { StorageService } from './helper/storage';
import { BsModalService } from 'ngx-bootstrap/modal';
import { RouterModule } from '@angular/router';
import { LayoutsModule } from './components/layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';

registerLocaleData(ptBr)
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    LayoutsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ProgressBarModule,
    ActionModalModule,
    SnackbarModule,
    NgxSnackBar,
    NgxSnackBar.forRoot()
  ],
  providers: [
    Location,
    CookieService,
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: LOCALE_ID,
      useValue: "pt"
    },
    StorageService,
    BsModalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
