import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/helper/auth.guard';
import { FooterOnlyComponent } from './components/layouts/footer-only/footer-only.component';

const routes: Routes = [
  {
    path: '',
    component: FooterOnlyComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () =>
          import('src/app/page/company/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'termos-de-uso',
        loadChildren: () =>
          import('src/app/page/termos/termos.module').then((m) => m.TermosModule),
      },
      {
        path: 'pagina',
        children: [
          {
            path: 'preview/:id_match_company',
            canActivate: [AuthGuard],
            data: { roles:  ['Owner', 'Employee-3', 'Employee-2'] },
            loadChildren: () => import('src/app/page/previewempresa/previewempresa.module').then((m) => m.PreviewempresaModule),
          },
          {
            path: 'empresa/:id_match_company',
            loadChildren: () => import('src/app/page/page/page.module').then((m) => m.PageModule),
          },
        ]
      },
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'reports',
        loadChildren: () =>
          import('src/app/page/match-report/match-report.module').then((m) => m.MatchReportModule),
      },
      {
        path: 'course-goals/:id_formative_itinerary',
        loadChildren: () =>
          import('src/app/page/courses-goals/courses-goals.module').then((m) => m.CoursesGoalsModule),
      },
      {
        path: 'empresa/fit-cultural',
        canActivate: [AuthGuard],
        pathMatch: 'full',
        data: { roles:  ['Owner', 'Employee-3'] },
        loadChildren: () =>
          import('src/app/page/company/fit-cultural/fit-cultural.module').then(
            (m) => m.FitCulturalModule
          ),
      },
      {
        path: 'empresa/fit-cultural/:id_match_vacancy',
        canActivate: [AuthGuard],
        pathMatch: 'full',
        data: { roles:  ['Owner', 'Employee-3'] },
        loadChildren: () =>
          import('src/app/page/company/fit-cultural/fit-cultural.module').then(
            (m) => m.FitCulturalModule
          ),
      },
      {
        path: 'jovem',
        children: [
          {
            path: 'dashboard',
            data: { roles: 'Young' },
            canActivate: [AuthGuard],
            loadChildren: () =>
              import('src/app/page/dashboard/dashboard.module').then((m) => m.DashboardModule)
          },
        ]
      },
      {
        path: '',
        loadChildren: () =>
          import('src/app/page/company/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'termos-de-uso',
        loadChildren: () =>
          import('src/app/page/termos/termos.module').then((m) => m.TermosModule),
      },
      {
        path: 'login',
        loadChildren: () =>
          import('src/app/page/login/login.module').then((m) => m.LoginModule),
      },
      {
        path: 'forgot',
        loadChildren: () =>
          import('src/app/page/company/forgot/forgot.module').then((m) => m.ForgotModule),
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        data: { roles: ['Owner', 'Employee-1', 'Employee-2', 'Employee-3', 'Admin', 'Superadmin'] },
        loadChildren: () =>
          import('src/app/page/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'perfil',
        canActivate: [AuthGuard],
        data: { roles: ['Owner', 'Young', 'Employee-1', 'Employee-2', 'Employee-3'] },
        loadChildren: () =>
          import('src/app/page/perfil/perfil.module').then((m) => m.PerfilModule),
      },
      {
        path: 'alertastestecorporativo',
        loadChildren: () =>
          import(
            'src/app/page/alertastestecorporativo/alertastestecorporativo.module'
          ).then((m) => m.AlertastestecorporativoModule),
      },
      {
        path: 'assinatura',
        loadChildren: () =>
          import('src/app/page/assinatura/assinatura.module').then(
            (m) => m.AssinaturaModule
          ),
      },
      {
        path: 'assinaturagestao',
        loadChildren: () =>
          import('src/app/page/assinaturagestao/assinaturagestao.module').then(
            (m) => m.AssinaturagestaoModule
          ),
      },
      {
        path: 'assinaturahistorico',
        loadChildren: () =>
          import('src/app/page/assinaturahistorico/assinaturahistorico.module').then(
            (m) => m.AssinaturahistoricoModule
          ),
      },
      {
        path: 'admin',
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Superadmin'] },
        children: [
          {
            path: 'empresas',
            children: [
              {
                path: '',
                pathMatch: 'full',
                loadChildren: () =>
                import('src/app/page/admin/companies/companies.module').then((m) => m.CompaniesModule),
              },
              {
                path: 'vagas/:id_match_company',
                // pathMatch: 'full',
                loadChildren: () =>
                    import('src/app/page/company/vacancies/vacancies.module').then((m) => m.VacanciesModule),
              },
              {
                path: ':id_match_company/matches',
                // pathMatch: 'full',
                loadChildren: () =>
                    import('src/app/page/company/matches/matches.module').then((m) => m.MatchesModule),
              },
              {
                path: 'editar/:id_match_company',
                data: { roles: ['Superadmin'] },
                loadChildren: () =>
                import('src/app/page/company-profile/company-profile.module').then(
                    (m) => m.CompanyProfileModule
                ),
              },
              {
                path: 'criar',
                data: { roles: ['Superadmin'] },
                // pathMatch: 'full',
                loadChildren: () =>
                  import('src/app/page/company-profile/company-profile.module').then(
                    (m) => m.CompanyProfileModule
                ),
              },

            ]
          },
          {
            path: 'ongs',
            children: [
              {
                path: '',
                pathMatch: 'full',
                data: { roles: ['Superadmin'] },
                loadChildren: () =>
                  import('src/app/page/admin/ngo/ngo-home/ngo-home.module').then(
                    (m) => m.NgoHomeModule
                ),
              },
              {
                path: 'editar/:id_match_ngo',
                data: { roles: ['Superadmin'] },
                // pathMatch: 'full',
                loadChildren: () =>
                  import('src/app/page/admin/ngo/ngo-managment/ngo-managment.module').then(
                    (m) => m.NgoManagmentModule
                ),
              },

              {
                path: 'criar',
                data: { roles: ['Superadmin'] },
                // pathMatch: 'full',
                loadChildren: () =>
                  import('src/app/page/admin/ngo/ngo-managment/ngo-managment.module').then(
                    (m) => m.NgoManagmentModule
                ),
              },
            ]
          }
        ]
      },
      {
        path: 'empresa',
        canActivate: [AuthGuard],
        data: { roles: ['Owner', 'Employee-3','Employee-2', 'Employee-1'] },
        children: [
          {
            path: '',
            data: { roles: ['Owner', 'Employee-3', 'Employee-2'] },
            loadChildren: () =>
              import('src/app/page/company/membro/membro.module').then((m) => m.MembroModule)
          },
          {
            path: 'gerenciar-conta',
            data: { roles: ['Owner', 'Employee-3'] },
            loadChildren: () =>
              import('src/app/page/company-profile/company-profile.module').then(
                (m) => m.CompanyProfileModule
            ),
          },
          {
            path: 'matches',
            canActivate: [AuthGuard],
            data: { roles:  ['Owner', 'Employee-3', 'Employee-1', 'Employee-2'] },
            loadChildren: () =>
              import('src/app/page/company/matches/matches.module').then((m) => m.MatchesModule),
          },
          {
            path: 'vagas',
            canActivate: [AuthGuard],
            children: [
              {
                path: 'criar',
                data: { roles:  ['Owner', 'Employee-3', 'Employee-2'] },
                loadChildren: () =>
                  import('src/app/page/company/vacancy/create-edit/vacancy.module').then(
                    (m) => m.CriacaovagasModule
                  ),
              },
              {
                path: 'editar/:id_match_vacancy',
                data: { roles:  ['Owner', 'Employee-3', 'Employee-2'] },
                loadChildren: () =>
                  import('src/app/page/company/vacancy/create-edit/vacancy.module').then(
                    (m) => m.CriacaovagasModule
                  ),
              },
              {
                path: 'modelos',
                data: { roles:  ['Owner', 'Employee-3', 'Employee-2'] },
                loadChildren: () =>
                  import('src/app/page/company/vacancy-models/vacancy-models.module').then(
                    (m) => m.VacancyModelsModule
                  ),
              },
              {
                path: '',
                data: { roles:  ['Owner', 'Employee-3', 'Employee-2', 'Employee-1'] },
                loadChildren: () =>
                  import('src/app/page/company/vacancies/vacancies.module').then(
                    (m) => m.VacanciesModule
                  ),
              },
            ],
          },
          {
            path: 'setup',
            data: { roles: ['Employee-2', 'Employee-3', 'Owner'] },
            canActivate: [AuthGuard],
            loadChildren: () =>
              import('src/app/page/company/setup/setup.module').then((m) => m.SetupModule),
          },
        ]
      },
    ],
  },
  { path: '**', redirectTo: 'jovem', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollOffset: [0, 0],
    anchorScrolling: "enabled",
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


