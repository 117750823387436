import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Filter } from 'src/app/model/companies';
import { Address } from 'src/app/model/addresses';


@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private http: HttpClient) { }

  getFields() {
    return this.http.get<any>(`${environment.url_api}fields`)
  }

  getLocations() {
    return this.http.get<any>(`${environment.url_api}locations`)
  }

  getStates() {
    return this.http.get<any>(`${environment.url_api}states`)
  }

  getCities() {
    return this.http.get<any>(`${environment.url_api}cities`)
  }

  getBenefits() {
    return this.http.get<any>(`${environment.url_api}benefits`)
  }

  getOccupations() {
    return this.http.get<any>(`${environment.url_api}benefits`)
  }

  getSkills() {
    return this.http.get<any>(`${environment.url_api}skills`)
  }

  getDetailedSkills() {
    return this.http.get<any>(`${environment.url_api}skills/details`)
  }

  getCourses(courseType?: string) {
    let query = courseType ? `?courseType=${courseType}` : ''
    return this.http.get<any>(`${environment.url_api}courses${query}`)
  }

  getAuthCourses(courseType?: string) {
    let query = courseType ? `?courseType=${courseType}` : ''
    return this.http.get<any>(`${environment.auth_url_api}courses${query}`)
  }

  getFormattedAddress = (obj: {address?: Address[]}) => {
    if(obj.address) {
        return `${obj.address[0].city}, ${obj.address[0].uf} `
      } else {
        return `Não informado`
    }
  }

}
