interface Ethnicity {
    id_ethnicity: number,
    description: string
}

export const ethnicities: Ethnicity[] = [
    {
      id_ethnicity: 1,
      description: "Preta"
    },
    {
      id_ethnicity: 2,
      description: "Parda"
    },
    {
      id_ethnicity: 3,
      description: "Amarela"
    },
    {
      id_ethnicity: 4,
      description: "Indígena"
    },
    {
      id_ethnicity: 5,
      description: "Branca"
    }
]