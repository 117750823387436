import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  init: boolean = false;
  show: boolean = false;
  constructor() { }
}
