<div class="modal-header px-5">
    <h1>Compartilhe o NAU</h1>
    <span (click)="onCloseCall()" class='icon-close'></span>
</div>
<div class="modal-body mb-4 px-5">
    <h2>Compartilhe nas suas redes sociais!</h2>
    <ul>
        <li class='mr-3' style="background-color:  #3c5b9a; color: #fff">
            <a href="{{facebookLink}}" target="_blank">
                <i class="fab fa-facebook-f fa-fw"></i>
            </a>
        </li>
        <li style="background-color:  #25D366; color: #fff">
            <a href="{{whatsAppLink}}" target="_blank">
                <i class="fab fa-whatsapp fa-fw"></i>
            </a>
        </li>
    </ul>
    <h2>Ou copie o link</h2>
    <div class='input-group w-100'>
        <input
            id="nau-input"
            class='form-control'
            readonly
            [value]="nauMatchLink"
        >
        <div class="input-group-prepend">
            <button class='btn btn-primary' (click)="onCopyNauLink()">
                <i class="fa fa-copy"></i>
            </button>
        </div>
    </div>
    <p class='my-3' *ngIf="isCopied">
        Link copiado!
    </p>
</div>