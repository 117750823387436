import { ChangeDetectorRef, Component,EventEmitter,Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable } from 'rxjs';
import { emailConfirmationConfig } from 'src/app/mockup/loginConstants';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment.prod';

export type Step = 'selection' | 'login' | 'register' | 'forgot' | 'close' | 'forgotForm' | 'extra-step'

interface extraStep {
  title: string, paragraph: string, buttonLabel: string
}
@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnDestroy, OnChanges, OnInit {
  @Input() public isOpen?: boolean = false
  @Input() public initialStep: Step = 'selection'
  @Input() public extraStepConfig?: extraStep

  @ViewChild('authModal') public authModal!: TemplateRef<any>;
  private currentStepsBs: BehaviorSubject<Step> = new BehaviorSubject<Step>('selection')
  public _currentStep: Observable<Step> = this.currentStepsBs.asObservable();
  jwtToken?: string
  email?: string
  
  loginModalRef?: BsModalRef | null;
  @Output() onClose = new EventEmitter

  loginConfig = {
    class:'login-modal',
    ignoreBackdropClick: false,
    backdrop: true
  }
  extraStepTitle: string = 'Acesse seu e-mail para redefinir sua senha'
  extraStepParagraph: string = 'Te mandamos um e-mail para você conseguir criar uma nova senha para a sua conta do NAU Match.'
  extraStepButtonLabel: string = 'Ok'

  constructor(
    private modalService: BsModalService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.onGetToken()
  }

  onGetToken() {
    this.jwtToken = undefined
    if(this.route.firstChild) {
      this.route.firstChild.queryParamMap.subscribe((p: any) => {
        this.jwtToken = p.params.token;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges | any):void {
    if (this.isOpen !== undefined && this.isOpen !== null) {
      if (!!this.isOpen) {
        this.onGetToken()
        if (!this.jwtToken) {
          this.changeStep('selection')
        } else {
          this.getUserByToken()
        }

        if(this.initialStep) {
          this.onInitialStep()
        }
        this.onShowModal()
      }
      else if (!this.isOpen) {
        this.onCloseCall()
      }
    }
  }

  onInitialStep() {
    if(this.extraStepConfig){
      this.onExtraStepFieldsChange(this.extraStepConfig)
    }
    this.changeStep(this.initialStep)
  }

  ngOnDestroy(): void {
    this.onCloseCall()
  }

  changeStep(step: Step) {
    if (step === 'close') {
      this.onCloseCall();
      return;
    }
    this.currentStepsBs.next(step);
  }

  async redirectToGoogleLogin() {
    let googleUrl;
    // this.authenticationService.getGoogleAuthUrl().subscribe(data => {
    //   ;
    //   googleUrl = data
    // })
    // ;
    document.location.href = environment.googleUrl
  }


  onShowModal() {
    const url = this.router.url
    if(!url.match('login')) {
      this.router.navigate([`login/${this.jwtToken || ''}`], {relativeTo: this.route})
    }
    this.loginModalRef = this.modalService.show(this.authModal, this.loginConfig);
    this.loginModalRef.onHide?.subscribe((reason: string | any) => {
      if(reason === 'backdrop-click') {
        this.onCloseCall(); // destroys component on backdrop click
      }
    })
  }

  getUserByToken() {
    const token = {
      authorization: `Bearer ${this.jwtToken}`
    }

    this.userService.getUserByToken(token).subscribe(data => {
      this.email = data.email
      this.changeStep('forgotForm')
    }, error => {
      this.changeStep('selection')
    })
  }


  onExtraStepFieldsChange(event: extraStep){
    this.extraStepTitle = event.title;
    this.extraStepParagraph = event.paragraph;
    this.extraStepButtonLabel = event.buttonLabel;
  }

  onExtraStepFieldsReset(){
    this.extraStepTitle = 'Acesse seu e-mail para redefinir sua senha'
    this.extraStepParagraph = 'Te mandamos um e-mail para você conseguir criar uma nova senha para a sua conta do NAU Match.'
    this.extraStepButtonLabel= 'Ok'
  }


  onCloseCall() {
    this.loginModalRef?.hide()
    this.router.navigate(['.'], {relativeTo: this.route})
    this.onClose.emit()

    setTimeout(() => {
      this.onExtraStepFieldsReset()
    }, 300)
  }

}
