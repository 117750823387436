import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  modalRef?: BsModalRef | null;
  config = {
    class: 'spread-modal',
    ignoreBackdropClick: false,
    backdrop: true
  }

  constructor(
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
  }

  openNauLinkModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  onClose() {
    this.modalRef?.hide()
  }

  getYear() {
    return new Date().getFullYear();
  }

}
