import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { UserService } from 'src/app/services/user/user.service';
import { SHA1 } from 'crypto-js';
import { SnackbarService } from 'ngx-snackbar';

@Component({
  selector: 'forgot-password-form',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  @Input() labelCustomClass: string = 'body-md bold'
  @Input() email?: string
  @Input() token?: string

  @Input() userError?: boolean
  @Input() passwordError?: boolean
  @Output() onRequestEnd = new EventEmitter()

  isPassVisible?: boolean
  tokenError?: boolean
  error?: boolean
  isConfirmPassVisible?: boolean

  constructor(
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService,
    private loadingService: LoadingService,
    private userService: UserService
  ) {
    this.form = this.formBuilder.group(
      {
        email: ['', [Validators.required]],
        password: [null, [Validators.required, Validators.minLength(6)]],
        confirmPassword: [null, [Validators.required, , this.samePasswordValidator()]],
      }
    );
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit() {
    this.f.email.setValue(this.email)
  }

  samePasswordValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      return !(value && this.f.password.value === value) ?  {samePasswordError: true} : null;
    }
  }
  onChangePassword() {
    this.passwordError = undefined;
    this.userError = undefined;
    this.tokenError = undefined
    this.error = undefined
    const headers: HttpHeaders = new HttpHeaders({
      authorization: 'Bearer ' + this.token
    })
    const password = SHA1(this.form.value.confirmPassword).toString()
    const user = {
      email: this.form.value.email,
      password
    }
    this.userService.changePassword(user, headers).subscribe(data => {
      this.loadingService.show = false
      this.snackSuccess()
      this.onRequestEnd.emit(true)
    }, err => {
      this.loadingService.show = false
      if(err.error.password === 'password'){
        this.passwordError = true
      }
      if(err.status === 401) {
        this.tokenError = true
      } else {
        this.error = true
      }
    })
  }

  snackSuccess() {
    this.snackbarService.add({
      msg: `<strong>Senha alterada com sucesso!</strong>`,
      timeout: 5000,
      action: {
        text: '',
        onClick: () => {

        }
      }
    });
  }

}
