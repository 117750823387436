import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerticalArrowComponent } from './vertical-arrow/vertical-arrow.component';
import { PopoverModule } from 'ngx-bootstrap/popover';

@NgModule({
  declarations: [
    VerticalArrowComponent,
  ],
  imports: [
    CommonModule,
    PopoverModule
  ],
  exports: [VerticalArrowComponent]
})
export class IconsModule { }
