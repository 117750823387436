import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-only',
  templateUrl: './footer-only.component.html',
  styleUrls: ['./footer-only.component.scss']
})
export class FooterOnlyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
