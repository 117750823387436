import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

type Icon = 'chevron' | 'arrow'

@Component({
  selector: 'vertical-arrow',
  templateUrl: './vertical-arrow.component.html',
  styleUrls: ['./vertical-arrow.component.scss']
})
export class VerticalArrowComponent implements OnInit {
  @Input() isUpside: boolean = false
  @Input()iconType: Icon = 'arrow'
  @Input() triggerType?: string = 'mouseenter:mouseleave'
  @Input() popoverText?: string
  @Output() isUpSideEvent = new EventEmitter

  constructor() { }

  ngOnInit(): void {
  }

  onIconClick() {
    this.isUpside = !this.isUpside
    this.isUpSideEvent.emit(this.isUpside)
  }

}
