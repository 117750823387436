interface Gender {
    id_match_gender: number,
    description: string
}

export const genders: Gender[] = [
    {
      id_match_gender: 1,
      description: "Mulher cis"
    },
    {
      id_match_gender: 2,
      description: "Mulher trans"
    },
    {
      id_match_gender: 3,
      description: "Homem cis"
    },
    {
      id_match_gender: 4,
      description: "Homem trans"
    },
    {
      id_match_gender: 5,
      description: "Não-binário"
    },
    {
      id_match_gender: 6,
      description: "Travesti"
    },
    {
      id_match_gender: 7,
      description: "Prefiro não declarar"
    },
]
