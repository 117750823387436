import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent implements OnInit {
  @HostBinding('style.-webkit-mask-image')
  private _path!: string;

  @Input() public set path(filePath: string) {
    this._path = `url("${filePath}")`;
  }
  constructor() { }

  ngOnInit(): void {
  }


}
