import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'spread-modal',
  templateUrl: './spread-modal.component.html',
  styleUrls: ['./spread-modal.component.scss']
})
export class SpreadModalComponent implements OnInit {
  @Output() onClose = new EventEmitter

  nauMatchLink = environment.nau_url
  facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${this.nauMatchLink}&src=sdkpreparse`
  whatsAppLink = `https://api.whatsapp.com/send?text=${this.nauMatchLink}`
  isCopied?: boolean
  constructor() { }

  ngOnInit(): void {
  }
  
  onCopyNauLink() {
    const nauLink: any = document.getElementById("nau-input") as HTMLElement;
    nauLink.select();
    nauLink.setSelectionRange(0, 99999); /* For mobile devices */

    navigator.clipboard.writeText(nauLink.value);
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false
    }, 2000)
  }

  onCloseCall() {
    this.onClose.emit()
  }

}
