<ng-template #actionModal>
    <div class="modal-body {{customClass || ''}}">
        <div class='row w-100 m-0 justify-content-center text-center'>
            <div class='col-12 row justify-content-center'>
                <div class='d-block'>
                    <div class="p-0 circle rounded-circle mt-4"
                        [ngClass]="roundedCircle ? 'bg-white' : '' ">
                        <img [src]="iconSource || '/assets/icons/hired.svg'">
                    </div>
                </div>
              </div>
            <div class='col-12 mt-4'>
                <h3 [innerHTML]="title"></h3>
                <p class='body-md-2' [innerHTML]="description"></p>
            </div>
            <ng-content></ng-content>
            <div class='container row px-4 mb-3'>
                <div class='col-6 my-5'>
                    <button class='btn btn-outline-primary w-100'
                        (click)="onCancelEvent()"
                    >
                        {{cancelLabel || 'Cancelar'}}
                    </button>
                </div>
                <div class='col-6 my-5'>
                    <button class='btn btn-primary w-100'
                        (click)="onConfirmEvent()"
                    >
                        {{confirmLabel || 'Confirmar'}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>