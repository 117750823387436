import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { links, authenticatedLinks } from 'src/app/mockup/youngLinks'
import { Link } from '../header/header.component';
import { MatchYoung } from 'src/app/model/match-young';
import { MatchOwner } from 'src/app/model/match-owner';
import { MatchEmployee } from 'src/app/model/match-employee.class';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, OnChanges {
  @Input() links: Link[] = []
  @Input() isOpen?: boolean
  @Input() user?: MatchYoung | MatchOwner | MatchEmployee
  @Input() isYoungPage?: boolean

  isYoung?: boolean
  hasHomeLinks: boolean = true
  isAuthenticated?: boolean

  @Output() onClose = new EventEmitter
  constructor(
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.isYoung = this.authenticationService.verifyRoles('Young')
    this.isAuthenticated = this.authenticationService.isAuthenticated()
    if(this.links.length === 0) {
      if (this.isYoung) {
        this.links = authenticatedLinks
      } else {
        this.links = links
      }
    }
  }

  ngOnChanges(){
    if(this.isOpen) {
      document.getElementById("sidenav").style.width = "300px";
    }
  }

  closeNav() {
    this.onClose.emit()
    document.getElementById("sidenav").style.width = "0px";
  }

  logout = (): void => {
    this.authenticationService.logout(true)
  }

}
