import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Filter } from 'src/app/model/companies';

@Injectable({
  providedIn: 'root'
})
export class CandidaciesService {

  constructor(private http: HttpClient) { }


  getCandidacies(filter: any) {
    const { match, pendency, progress, finished } = filter
    return this.http.get<any>(`${environment.auth_url_api}candidacies?match=${match}&pendency=${pendency}&progress=${progress}&finished=${finished}`)
  }

  getPendingCandidacies(id_match_vacancy: number, filter: any, currentPage: number, isAdmin?: boolean, id_match_company?: number) {
    let requestQuery = ''
    if(filter && filter !== '') {
      requestQuery = `${filter}&currentPage=${currentPage}`
    } else {
      requestQuery = `?currentPage=${currentPage}`
    }

    if(isAdmin) {
      return this.http.get<any>(`${environment.auth_url_api}company/${id_match_company}/candidacies/${id_match_vacancy}${requestQuery}`)
    }

    return this.http.get<any>(`${environment.auth_url_api}company/candidacies/${id_match_vacancy}${requestQuery}`)
  }

  putMatches(id_match_vacancy: number) {
    return this.http.put<any>(`${environment.auth_url_api}matches/${id_match_vacancy}`, {})
  }

  selectCandidacies(candidacies: number[]) {
    return this.http.post<any>(`${environment.auth_url_api}candidacies/select`, {candidacies})
  }

  post(obj: any) {
    return this.http.post<any>(`${environment.auth_url_api}candidacy`, {...obj})
  }

  updateByYoung(obj: any) {
    return this.http.post<any>(`${environment.auth_url_api}candidacies/youngs/update`, {...obj})
  }

  updateByCompany(obj: any) {
    return this.http.post<any>(`${environment.auth_url_api}candidacies/companies/update`, {...obj})
  }

  favorite(id_candidacy: number) {
    return this.http.put<any>(`${environment.auth_url_api}candidacies/favorite/${id_candidacy}`, {})
  }
}
