import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { FooterOnlyComponent } from './footer-only/footer-only.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    FooterOnlyComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
  ],
  exports: [
    FooterOnlyComponent,
  ]
})
export class LayoutsModule { }
