import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginModalComponent } from './login-modal.component';
import { FormComponent } from './form/form.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { YoungRegisterModule } from '../../forms/young-register/young-register.module';
import { ErrorModule } from '../../elements/error/error.module';
import { ForgotPasswordModule } from '../../forms/forgot-password/forgot-password.module';

@NgModule({
  declarations: [
    LoginModalComponent,
    FormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    YoungRegisterModule,
    ErrorModule,
    ForgotPasswordModule
  ],
  exports: [LoginModalComponent]
})
export class LoginModalModule { }
