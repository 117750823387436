import { Address } from "./addresses";
import { MatchGender } from "./match-gender";
import { User } from "./user.class";

class AddressClass {
  id_address: number
  id_match_city: number
  id_match_uf?: number
  city: string
  uf: string
  address: string
  address_number: string | number
}

export class MatchYoung extends User {
  id_match_young?: number;
  photo?: string;
  userId: number
	address?: Address = new AddressClass()
  login: string
  id_match_ngo?: number
  login_token: string
  /* take it out on update */
  id_match_city?: number
  id_status?: number;
	id_match_gender?: number;
  id_deficiency?: number;
  id_ethnicity?: number;
  id_scholarity?: number | null;
  professional_experience?: string;
  match_gender?: MatchGender;
  status?: string;
  // Atributos Local
  token?: string;
  id_profile?: number;
  id_user?: number;

  // Table match_cities, match_uf e user_system_subscription
  cidade: string = '';
  uf: string = '';
  birth_date?: string;

}
