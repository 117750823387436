<footer class="footer p-lg-5 p-md-5 p-4">
  <div class="row mt-5 pt-5 w-100 m-0 justify-content-center footer-container">
    <div class="col-lg-4 col-md-4 p-0">
      <div class='row w-100 m-0'>
        <div class='col-auto'>
          <p class='body-md'><b>Uma iniciativa</b></p>
        </div>
        <div class='col-12'>
          <div class='row w-100 m-0 justify-content-lg-between align-items-lg-end responsive-flex'>
              <div class='col-6 pl-0'>
                <img
                  class="img-fluid brand"
                  src="/assets/img/brand/logo-tamboro.png"
                />
              </div>
              <div class='col-6 mt-md-3 mt-0 pr-0'>
                <img
                  class="img-fluid brand"
                  src="/assets/img/brand/seb_logo.png"
                />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-4 mt-lg-0 mt-md-0 mt-5">
      <p class='body-md'><b>Sobre nós</b></p>
      <ul>
        <li><a href="https://www.projetonau.com.br/naumercadodetrabalho" target="_blank">Projeto NAU</a></li>
        <li><a href="https://tamboro.com.br/" target="_blank">Tamboro</a></li>
        <li><a (click)="openNauLinkModal(modal)">Divulgue o NAU</a></li>
      </ul>
    </div>
<!--     <div class="col-lg-3 col-md-4 col-sm-12 mt-lg-0 mt-md-0 mt-5">
      <p class="d-flex w-100 align-content-start"><b>Nossas Redes Sociais</b></p>
      <div class='row justify-content-start w-100 m-0'>
        <div class='col-auto p-0'>
          <img width="40px" src="../../../assets/img/home/icon-facebook.svg"/>
        </div>
        <div class='col-auto px-2'>
          <img width="40px" src="../../../assets/img/home/icon-linkedin.svg"/>
        </div>
        <div class='col-auto px-2 pl-2 pl-md-0'>
          <img width="40px" src="../../../assets/img/home/icon-insta.svg"/>
        </div>
        <div class='col-auto px-2 px-md-0'>
          <img width="40px" src="../../../assets/img/home/icon-twitter.svg" />
        </div>
      </div>
    </div> -->
    <div class="copyright w-100 mt-5">
      <p class="m-0">
        NAU Match © Copyright {{ getYear() }} - Todos os direitos reservados - <a href="termos-de-uso" target="_blank">Termos de uso</a>
      </p>
    </div>
  </div>
</footer>


<ng-template #modal>
  <spread-modal
    (onClose)="onClose()"
  ></spread-modal>
</ng-template>
