import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChange, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'action-modal',
  templateUrl: './action-modal.component.html',
  styleUrls: ['./action-modal.component.scss']
})
export class ActionModalComponent implements OnChanges, OnDestroy {
  @Input() isOpen?: boolean
  @Input() hasRoute?: string
  @Input() iconColor?: string = '#ff0000'
  @Input() customClass?: string
  @Input() iconSource?: string = '/assets/icons/hired.svg'
  @Input() title?: string = "Deseja realizar essa ação?"
  @Input() description?: string = "Esta ação não pode ser desfeita"
  @Input() cancelLabel?: string = "Cancelar"
  @Input() confirmLabel?: string = "Confirmar"

  @Input() roundedCircle?: boolean = true
  @Output() onCancel = new EventEmitter()
  @Output() onConfirm = new EventEmitter()
  @Output() onClose = new EventEmitter()

  @ViewChild('actionModal') public actionModal!: TemplateRef<any>;
  actionModalRef?: BsModalRef | null;

  onHideSubscription?: Subscription

  config = {
    class: 'small-modal',
    ignoreBackdropClick: false,
    backdrop: true
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
  ) { }

  ngOnChanges(changes: SimpleChange | any): void {
    if(this.isOpen !== undefined && this.isOpen !== null) {
      if (!!this.isOpen) {
        this.onShowModal()
      }
      else if (!this.isOpen && !!changes.isOpen.previousValue) {
        this.onCloseCall()
      }
    }
  }

  ngOnDestroy(){
    if(this.onHideSubscription) {
      this.onHideSubscription.unsubscribe()
    }
  }

  onConfirmEvent() {
    this.onConfirm.emit(true)
  }

  onCancelEvent() {
    this.onCancel.emit(true)
  }

  onShowModal() {
    if (this.hasRoute) {
      this.router.navigate([`${this.hasRoute}`], {relativeTo: this.route})
    }
    this.actionModalRef = this.modalService.show(this.actionModal, this.config)
    this.onHideSubscription = this.actionModalRef.onHide?.subscribe((reason: string | any) => {
      if(reason === 'backdrop-click') {
        this.onCloseCall() // destroys component on backdrop click
      }
    })
  }


  onCloseCall() {
    this.onHideSubscription.unsubscribe()
    this.actionModalRef?.hide()
    // this.router.navigate(['.'], {relativeTo: this.route})
    this.onClose.emit()
  }

}
