import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {
  @Input() description?: string = ''
  @Input() cancelLabel: string =  'Cancelar'
  @Input() confirmLabel: string =  'Confirmar'
  @Input() isOpen?: boolean

  @Output() onCancel = new EventEmitter()
  @Output() onConfirm = new EventEmitter()
  constructor() { }

  ngOnInit(): void {
  }

  onCancelHandle() {
    this.onCancel.emit()
  }

  onConfirmHandle() {
    this.onConfirm.emit()
  }

}
