import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HeaderComponent } from './header/header.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { SpreadModalModule } from 'src/app/components/modals/spread-modal/spread-modal.module';
import { LoginModalModule } from 'src/app/components/modals/login-modal/login-modal.module';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { IconsModule } from '../elements/icons/icons.module';
@NgModule({
  declarations: [
    FooterComponent,
    NavbarComponent,
    HeaderComponent,
    SideMenuComponent,
  ],
  imports: [
    SpreadModalModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    IconsModule,
    LoginModalModule,
    CommonModule
  ],
  exports: [FooterComponent, NavbarComponent, HeaderComponent, SideMenuComponent]
})
export class SharedModule { }
