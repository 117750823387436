import { Injectable } from '@angular/core';
import { CookieService as CkService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor(
    private ckService: CkService
  ) { }

  get(name: string) {
    console.log(this.ckService.getAll())
    return this.ckService.get(name)
  }

  remove(name: string): void {
    this.ckService.delete(name)
  }

}
