import { Injectable } from '@angular/core';
import { WindowService } from './window.service';

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  private window; 

  constructor (private windowService: WindowService)
  {
      this.window = windowService.nativeWindow; // intialise the window to what we get from our window service

  }

   private pingHome(obj)
   {
       if(obj)  this.window.dataLayer.push(obj);
   }
  
  
  //list of all our dataLayer methods

  logPageView(url)
  {
      const hit = {
          event: 'content-view',
          pageName: url
      };
      this.pingHome(hit);
  }

  logEvent(event,category,action,label)
  {
      const hit = {
          event:event,
          category:category,
          action:action,
          label: label
      }
       this.pingHome(hit);
  }

  logCustomDimension(event, value)
  {
      const hit = {
          event,
          value
      }
      this.pingHome(hit);
  }
}
