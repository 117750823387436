<form [formGroup]="form">
  <div class="row w-100 mx-0">
    <div class="col-xl-5 col-lg-5 col-12">
      <input
        [(ngModel)]="young.first_name"
        class="form-control"
        formControlName="first_name"
        placeholder="Nome"
        [ngClass]="{ 'is-invalid': f.first_name.touched && f.first_name.errors }"
      />
    </div>
    <div class="col-xl-7 col-lg-7 col-12">
      <input
        [(ngModel)]="young.last_name"
        class="form-control"
        formControlName="last_name"
        placeholder="Sobrenome"
        [ngClass]="{ 'is-invalid': f.last_name.touched && f.last_name.errors }"
      />
    </div>
    <div class="col-lg-6 col-12">
      <input
        [(ngModel)]="young.email"
        type="email"
        class="form-control"
        formControlName="email"
        placeholder="E-mail"
        [ngClass]="{ 'is-invalid': f.email.touched && f.email.errors || emailError}"
      />
      <app-error [showError]="f.email.errors?.email" [customClass]="'mb-0'" [error]="'E-mail inválido'"></app-error>
      <app-error [showError]="emailError" [customClass]="'mb-0'" [error]="'Esse e-mail já está sendo usado'"></app-error>
    </div>
    <div class="col-lg-6 col-12">
      <input
        [(ngModel)]="email_c"
        type="email"
        class="form-control"
        formControlName="email_c"
        placeholder="Confirmar E-mail"
        [ngClass]="{ 'is-invalid': f.email_c.touched && f.email_c.errors }"
      />
      <app-error [showError]="f.email_c.errors?.noMatch" [customClass]="'mb-0'" [error]="'Confira novamente seu e-mail. Letras maiúsculas e símbolos devem estar digitados iguais.'"></app-error>
    </div>
    <div class="col-12">
      <input
        [(ngModel)]="young.phone_number"
        type="text"
        class="form-control"
        mask="(00) 0000-0000||(00) 00000-0000"
        formControlName="phone_number"
        placeholder="Whatsapp"
        [ngClass]="{ 'is-invalid': f.phone_number.touched && f.phone_number.errors}"
      />
      <app-error [showError]="f.phone_number.errors?.minlength" [customClass]="'mb-0'" [error]="'Telefone inválido'"></app-error>
    </div>
    <div class="col-12">
      <input
        [(ngModel)]="young.password"
        type="password"
        class="form-control"
        formControlName="pass"
        placeholder="Senha"
        [ngClass]="{ 'is-invalid': f.pass.touched && f.pass.errors }"
      />
      <app-error [showError]="f.pass.errors?.minlength" [customClass]="'mb-0'" [error]="'A senha precisa ter no mínimo 6 dígitos'"></app-error>
    </div>
    <div class="col-12">
      <input
        [(ngModel)]="pass_c"
        type="password"
        class="form-control"
        formControlName="pass_c"
        placeholder="Confirmar Senha"
        [ngClass]="{ 'is-invalid': f.pass_c.touched && f.pass_c.errors }"
      />
      <app-error [showError]="f.pass_c.errors?.noMatchPass" [customClass]="'mb-0'" [error]="'Confira se digitou sua senha corretamente'"></app-error>
    </div>
    <div class='col-lg-12'>
      <label class='body-md-2 bold {{classTermsLabel}}'>Você é jovem de alguma ONG ou Instituição parceira?</label>
      <div class="select-box">
          <select
            class="form-control"
            [(ngModel)]="young.id_match_ngo"
            formControlName="id_match_ngo"
            [ngClass]="{ 'is-invalid': f.id_match_ngo.touched && f.id_match_ngo.errors }"
          >
            <option selected [ngValue]="undefined">ONG ou Instituição parceira</option>
            <option *ngFor="let ngo of ngos" [value]="ngo.id_match_ngo">
              {{ ngo.fantasy_name }}
            </option>
          </select>
      </div>
      <app-error [showError]="f.id_match_ngo.errors?.required && f.id_match_ngo.touched" *ngIf="ngos" [customClass]="'mb-0'" [error]="'Campo obrigatório.'"></app-error>
  </div>
    <div class='col-lg-12'>
      <label class='body-md-2 bold {{classTermsLabel}}'>Para enviarmos vagas exclusivas para o seu perfil, a gente adoraria saber mais sobre você:
        <ng-template #popoverContainer>
          <span class='modalPopover' container="body">
            Aqui nós respeitamos, acolhemos e celebramos todas as individualidades. Saber sua etnia, identidade de gênero e
            deficiências é algo importante para enviarmos vagas exclusivas para grupos historicamente discriminados.
          </span>
        </ng-template>
        <svg-icon 
          containerClass="popoverClass"
          [popover]="popoverContainer"
          triggers="mouseenter:mouseleave"
          [path]="'/assets/img/icon-info.svg'" style="height: 16px; width: 16px;vertical-align: middle;" 
          [ngStyle]="{'background-color': classTermsLabel === 'text-light' ? 'white' : '#13103e' }"
          class='d-inline-block ml-1'>
        </svg-icon> 
      </label>
      <div class="select-box mb-3">
        <select class="form-control" [(ngModel)]="young.address.id_match_uf" formControlName="id_match_uf"
          [ngClass]="{ 'is-invalid': f.id_match_uf.touched && f.id_match_uf.errors }">
          <option selected [ngValue]="undefined">Onde você mora?</option>
          <option *ngFor="let state of states" [value]="state.id_match_uf">
            {{ state.description }}
          </option>
        </select>
        <app-error [showError]="f.id_match_uf.errors?.required && f.id_match_uf.touched" *ngIf="states"
          [customClass]="'mb-0'" [error]="'Campo obrigatório.'"></app-error>
      </div>
      <div class="select-box mb-3">
        <select class="form-control" [(ngModel)]="young.id_match_gender" formControlName="id_match_gender"
          [ngClass]="{ 'is-invalid': f.id_match_gender.touched && f.id_match_gender.errors }">
          <option selected [ngValue]="undefined">Com que gênero você se identifica?</option>
          <option *ngFor="let gender of genders" [value]="gender.id_match_gender">
            {{ gender.description }}
          </option>
        </select>
        <app-error [showError]="f.id_match_gender.errors?.required && f.id_match_gender.touched" *ngIf="genders" [customClass]="'mb-0'" [error]="'Campo obrigatório.'"></app-error>
      </div>
      <div class="select-box mb-3">
        <select class="form-control" [(ngModel)]="young.id_ethnicity" formControlName="id_ethnicity"
          [ngClass]="{ 'is-invalid': f.id_ethnicity.touched && f.id_ethnicity.errors }">
          <option selected [ngValue]="undefined">E qual é a sua etnia?</option>
          <option *ngFor="let ethnicity of ethnicities" [value]="ethnicity.id_ethnicity">
            {{ ethnicity.description }}
          </option>
        </select>
        <app-error [showError]="f.id_ethnicity.errors?.required && f.id_ethnicity.touched" *ngIf="ethnicities" [customClass]="'mb-0'" [error]="'Campo obrigatório.'"></app-error>
      </div>
    </div>
    <div class="col-12 text-left">
      <span class="{{classTermsLabel}} body-sm">Ao criar uma conta, você concorda com as Condições de Uso do NAU Match. Por favor, verifique a <a class="{{classTermsLabel}}" href="termos-de-uso" target="_blank">Política de Privacidade e a Política de Cookies</a>.</span>
    </div>
  </div>
  <div class="text-{{positionBtnSend}} mt-4">
    <button [disabled]="loading || !form.valid" (click)="send()" class="btn btn-lg {{classColorBtnSend}}">Enviar</button>
  </div>
</form>
