<form [formGroup]="form" (submit)="onChangePassword()" class='row w-100 mx-0'>
    <div class='col-12'>
        <label class='{{labelCustomClass}}'>Email</label>
        <input class='form-control' formControlName="email" [ngClass]="{ 'is-invalid': (f.email.touched && f.email.errors) ||  userError}">
        <app-error [showError]="userError" [customClass]="'mb-0'" [error]="'O e-mail digitado não existe.'"></app-error>
        <app-error [showError]="f.email.touched && !!f.email.errors" [customClass]="'mb-0'" [error]="'Campo obrigatório.'"></app-error>
    </div>
    <div class='col-12'>
        <label class='{{labelCustomClass}}'>Nova senha</label>
        <div class="input-box">
            <input class='form-control' [type]="isPassVisible ? 'text' : 'password'" formControlName="password" [ngClass]="{ 'is-invalid': f.password.touched && f.password.errors }">
            <img src="/assets/icons/eye-icon-black.svg" class='icon' (click)="isPassVisible = !isPassVisible"/>
        </div>
        <app-error [showError]="f.password.errors?.minlength" [customClass]="'mb-0'" [error]="'A senha precisa ter no mínimo 6 dígitos.'"></app-error>
    </div>
    <div class='col-12'>
        <label class='{{labelCustomClass}}'>Confirmação de senha</label>
        <div class="input-box">
            <input class='form-control' [type]="isConfirmPassVisible ? 'text' : 'password'" formControlName="confirmPassword" [ngClass]="{ 'is-invalid': f.confirmPassword.touched && f.confirmPassword.errors }"/>
            <img src="/assets/icons/eye-icon-black.svg" class='icon' (click)="isConfirmPassVisible = !isConfirmPassVisible" />
        </div>
        <app-error [showError]="f.confirmPassword.errors?.samePasswordError && f.confirmPassword.touched" [customClass]="'mb-0'" [error]="'Confira se digitou corretamente.'"></app-error>
    </div>
    <div class='px-3'>
        <app-error [showError]="tokenError" [customClass]="'mb-0'" [error]="'Token inválido.'"></app-error>
        <app-error [showError]="error" [customClass]="'mb-0'" [error]="'Erro no servidor.'"></app-error>
    </div>
    <div class='px-3 text-right w-100'>
        <button type="submit" [disabled]="!this.form.valid" class='btn btn-primary btn-lg mt-4'>Cadastrar nova senha</button>
    </div>
</form>