import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  isAuthModalOpen: boolean = false;
  // EventEmitter should not be used this way - only for `@Output()`s
  //isAuthModalOpenChange: EventEmitter<string> = new EventEmitter<string>();
  isAuthModalOpenChange: Subject<boolean> = new Subject<boolean>();
  constructor() {
  }
  change(isOpen: boolean){
    this.isAuthModalOpen = isOpen;
    this.isAuthModalOpenChange.next(this.isAuthModalOpen);
  }
}
