import { environment } from './../../../environments/environment';
import { MatchYoung } from '../../model/match-young';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageService } from 'src/app/helper/storage';
import { MatchEmployee } from 'src/app/model/match-employee.class';
import { MatchOwner } from 'src/app/model/match-owner';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  api = environment.url_api;
  storage: StorageService = new StorageService();

  private currentUserSubject: BehaviorSubject<MatchEmployee | MatchOwner | MatchYoung | null>;
    public currentUser: Observable<MatchEmployee | MatchOwner | MatchYoung | null>;


    constructor(private http: HttpClient, private router: Router, private location: Location) {
      const user: any = this.storage.get('currentUser');
      this.currentUserSubject = new BehaviorSubject<MatchEmployee | MatchOwner | MatchYoung | null>(user);
      this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): MatchEmployee | MatchOwner | MatchYoung | null {
      return this.currentUserSubject.value;
    }


    updateUser(user: MatchEmployee | MatchOwner | MatchYoung) {
      this.storage.set('currentUser', user);
      this.currentUserSubject.next(user);
    }

    login(post: any, remember: boolean) {
      return this.http.post<any>(`${this.api}login`, post)
        .pipe(map((data: any) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if (remember) {
            this.storage.set('currentUser', data.user);
          }
          this.currentUserSubject.next(data.user);
          if (this.verifyRoles('Young')) {
            this.router.navigate(['jovem/dashboard'])
          }
          return data.user;
        }
      ));
    }

    isAuthenticated(): boolean {
      return !!this.currentUserSubject.value
    }

    verifyRoles(roles: string[] | string): boolean {
      if(typeof roles == 'string') {
        roles = [roles]
      }
      const idRole = this.currentUserValue?.id_role as unknown as string
      const role = this.currentUserValue?.role as unknown as string
      if (roles.length && (
          !roles.includes(idRole) &&
          !roles.includes(role)
        )
      ){
        return false
      }
      return true;
    }

    logout(isReload?: boolean) {
        const wasYoung = this.verifyRoles('Young')
        // remove user from local storage to log user out
        this.storage.remove('currentUser');
        if (wasYoung && isReload) {
          window.location.reload()
        }
        else if (wasYoung && !isReload) {
          this.router.navigate(['jovem'])
        }
        if (!wasYoung) {
          window.location.href = '/login'
        }
        this.currentUserSubject.next(null);
    }
}
