import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YoungRegisterComponent } from './young-register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorModule } from '../../elements/error/error.module';
import { NgxMaskModule } from 'ngx-mask';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SvgIconModule } from '../../elements/svg-icon/svg-icon.module';


@NgModule({
  declarations: [
    YoungRegisterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    SvgIconModule,
    ErrorModule,
    PopoverModule
  ],
  exports: [
    YoungRegisterComponent
  ]
})
export class YoungRegisterModule { }
