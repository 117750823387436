import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Location } from '@angular/common';
import { CandidaciesService } from 'src/app/services/candidacies/candidacies.service';
import { LoadingService } from 'src/app/services/loading/loading.service';

@Component({
  selector: 'app-header-dashboard',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Input() dashboardBtnShow?: boolean;
  @Input() isMatchScreen?: boolean
  @Input() isRouteStategyRoute?: boolean
  @Input() backBtnShow?: boolean;
  @Input() buttonRoute?: string

  isYoung?: boolean
  constructor(
    private router: Router,
    private loadingService: LoadingService,
    private authenticationService: AuthenticationService,
    private candidaciesService: CandidaciesService,
    public location: Location, public authService: AuthenticationService) { }

  ngOnInit(): void {
    this.isYoung = this.authenticationService.verifyRoles('Young')
  }

  back() {
    if (this.buttonRoute) {
      this.router.navigate([`${this.buttonRoute}`])
    } else {
      this.location.back();
    }
  }

  redirect(link: string) {
    /* overrides membros/unities page route stategy */
    if(this.isRouteStategyRoute){
      this.router.routeReuseStrategy.shouldReuseRoute = function(){
        return false;
      } 
    }
    this.router.navigate([link])
  }

  logout() {
    this.authService.logout()
  }
}

