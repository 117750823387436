<ng-template #authModal>
    <div class="modal-header">
        <div class='button-wrapper w-100'>
            <button class='btn btn-primary rounded-circle rounded-circle shadow float-right' (click)="onCloseCall()">
                <i class='fa fa-times icon'></i>
            </button>
        </div>
    </div>
    <div class="modal-body mb-4 p-xl-5 p-lg-5">
        <div class='row w-100 align-items-center justify-content-center mx-0 pt-0'>
            <ng-container *ngIf="(_currentStep | async) === 'selection'">
                <div class='col-xl-6 col-lg-6 col-8 pl-0'>
                    <img class='img-fluid' src="../../../../assets/img/vagapublicada/concluido-vagapublicada.svg">
                </div>

                <div class='col-xl-6 col-lg-6 pr-0'>
                    <div class='d-flex w-100 mx-0 mt-xl-0 mt-lg-0 mt-5 flex-column justify-content-center'>
                        <h3 class='display-4'>Quer usar tudo o que o NAU Match pode oferecer?</h3>
                        <p class='body-xl'>
                            Crie sua conta agora e comece a se 
                            desenvolver para conquistar as vagas que têm a sua cara!
                        </p>
                        <div class='mt-5'>
                            <button
                                class='btn btn-primary action-button w-50'
                                (click)="changeStep('register') "
                                >Criar Conta
                            </button>
                        </div>
                        <div class='mt-4'>
                            <button
                                class='btn btn-outline-primary action-button w-50'
                                (click)="changeStep('login')"
                            >
                                Já tenho conta NAU Match
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="(_currentStep | async) === 'extra-step'">
                <div class='col-xl-6 col-lg-6 col-8 pl-0'>
                    <img class='img-fluid' src="/assets/img/vagapublicada/concluido-vagapublicada.svg">
                </div>

                <div class='col-xl-6 col-lg-6 pr-0'>
                    <div class='d-flex w-100 mx-0 mt-xl-0 mt-lg-0 mt-5 flex-column justify-content-center'>
                        <h3 class='display-4'>{{extraStepTitle}}</h3>
                        <p class='body-xl'>
                            {{extraStepParagraph}}
                        </p>
                        <div class='mt-4'>
                            <button
                                class='btn btn-primary action-button w-50'
                                (click)="changeStep('close')"
                            >
                                {{extraStepButtonLabel}}
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="(_currentStep | async) !== 'selection'">
                <div class='col-12 px-0'>
                    <modal-form
                        [token]="jwtToken"
                        [email]="email"
                        [whichStep]="(_currentStep | async)"
                        (changeStepEvent)="changeStep($event)"
                        (onExtraStepFieldsChange)="onExtraStepFieldsChange($event)"
                    ></modal-form>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>