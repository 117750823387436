import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatchYoung } from 'src/app/model/match-young';
import { UserService } from 'src/app/services/user/user.service';
import { SHA1 } from 'crypto-js';
import { NgoService } from 'src/app/services/ngo/ngo.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { genders } from 'src/app/mockup/genders';
import { ethnicities } from 'src/app/mockup/ethnicities';
import { States } from 'src/app/model/cities';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-young-register',
  templateUrl: './young-register.component.html',
  styleUrls: ['./young-register.component.scss']
})
export class YoungRegisterComponent implements OnInit {
  loading: boolean = false;
  errorMsg:string = '';
  form: FormGroup;
  young: MatchYoung = new MatchYoung();
  email_c: string;
  pass_c: string;
  submitted: boolean = false;
  states: States[] = [];
  genders = genders;
  ethnicities = ethnicities;
  ngos: any[] = []
  ngoUnities: any[] =[]
  emailError?: boolean

  @Input() classColorBtnSend: 'btn-primary' | 'btn-green' = 'btn-primary';
  @Input() positionBtnSend: 'center' | 'left' | 'right' = 'right';
  @Input() classErrorMsg: 'text-danger' | 'text-warning' | 'text-light' | 'text-dark' = 'text-danger';
  @Input() classTermsLabel: 'text-light' | 'text-dark' = 'text-dark';
  @Output() eventSendResult  = new EventEmitter();


  constructor(
      private formBuilder: FormBuilder,
      public userService: UserService,
      private ngoService: NgoService,
      private loadingService: LoadingService,
      private utilsService: UtilsService
  ) {
    this.form = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      email_c: ['', [Validators.required, Validators.email]],
      pass: ['', Validators.minLength(6)],
      pass_c: ['', [Validators.required]],
      phone_number: [null, [Validators.minLength(10), Validators.maxLength(11)]],
      id_match_ngo: ['', Validators.required],
      id_match_uf: ['', Validators.required],
      id_match_gender: ['', Validators.required],
      id_ethnicity: ['', Validators.required],
    }, { validators: [this.matchEmail, this.matchPassword] });
  }

  get f() { return this.form.controls; }

  matchEmail(control: AbstractControl): ValidationErrors | null {

    const email = control.get("email");
    const email_c = control.get("email_c");
    const pass = control.get("pass");
    const pass_c = control.get("pass_c");
    let result = null;

    /* do not dispatch error if one of them is null */
    if (email.value && email_c.value) {
      if(email.value.length !== 0 && email_c.value.length !== 0) {
        if (email.value !== email_c.value) {
          email_c.setErrors({ noMatch: true });
        }
      }
    }
    /* do not dispatch error if one of them is null */
    if (pass.value && pass_c.value) {
      if(pass.value.length !== 0 && pass_c.value.length !== 0) {
        if (pass.value !== pass_c.value) {
          pass_c.setErrors({ noMatchPass: true });
        }
      }
    }
    return result;
  }

  matchPassword(control: AbstractControl): ValidationErrors | null {
    const pass = control.get("pass").value;
    const confirm = control.get("pass_c").value;
    if (pass && confirm && pass !== '' && confirm !== '') {
      if (pass != confirm) return { noMatchPass: true } 
    }

    return null
  }

  ngOnInit(): void {
    this.getNgos()
    this.getStates()
   }

  async send() {
    this.emailError = false
    this.submitted = true;
    if (this.form.invalid) {
      this.errorMsg = 'Corrija os erros.';
      return;
    }
    this.loading = true;
    try {
      const result = await this.userService.registerYoung(
        { ...this.young, password: SHA1(this.young.password).toString() }
      ).toPromise();
      this.eventSendResult.emit({
        success: true,
        msg: 'Jovem criado com sucesso.'
      });
    } catch (e) {
      if(e.error.error  === 'email') {
        this.emailError = true
      }
      this.eventSendResult.emit({
        success: false,
        msg: e.error.message ? e.error.message : 'Ops, a conexão falhou.'
      });
    }
    this.loading = false;
  }

  getNgos() {
    this.loadingService.show = true
    this.ngoService.getNgoList().subscribe(data => {
      this.ngos = data
      this.loadingService.show = false
    }, error => {
      this.loadingService.show = false
    })
  }

  getNgoUnities() {
    const ngoControl = this.f.id_match_ngo
    if(ngoControl.value &&  ngoControl.value !== '' ) {
      this.ngoService.getNgoUnities(ngoControl.value as number).subscribe(data => {
        this.ngoUnities = data
      }, error => {
        this.loadingService.show = false
        this.ngoUnities = []
      })
    } else {
      this.ngoUnities = []
    }
  }

    getStates() {
    this.utilsService.getStates().subscribe(data => {
      this.states = data
    }, error => {

    })
  }
}
