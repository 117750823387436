import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  @Input() showError?: boolean | null = false;
  @Input() customClass?: string = ''
  @Input() error: string = ''; 
  constructor() { }

  ngOnInit(): void {
  }

}
