
export const links = [
    {
      url: "jovem/empresas",
      name: "Empresa"
    },
    {
      url: "jovem/vagas",
      name: "Vagas"
    },
    {
      url: "jovem/cursos",
      name: "Cursos e desafios"
    },
    {
      url: "",
      name: "Área da empresa"
    }
]

export const authenticatedLinks = [
    {
      url: "jovem/empresas",
      name: "Empresa"
    },
    {
      url: "jovem/vagas",
      name: "Vagas"
    },
    {
      url: "jovem/cursos",
      name: "Cursos e desafios"
    },
    {
      url: "jovem/minhas-candidaturas",
      name: "Candidaturas"
    },
]
