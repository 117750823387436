<div id="sidenav" class="sidenav" [style.box-shadow]="isOpen ? 
    'rgb(0 0 0 / 70%) 0px 8px 10px -5px, rgb(0 0 0 / 35%) 0px 16px 24px 2px, rgb(0 0 0 / 20%) 0px 6px 30px 5px' : ''
">
    <span *ngIf="isAuthenticated">
        <a class="body-xl pl-4 py-2 account-button row w-100 mx-0" (click)="hasHomeLinks = !hasHomeLinks"
            *ngIf="hasHomeLinks">
            <div class="photo rounded-circle mr-3">
                <div class='avatar'>
                    <img *ngIf="!user.photo" class="rounded-circle" src="assets/img/perfil/users.svg" />
                    <img class="rounded-circle" width="100%" *ngIf="user.photo" [src]="user.photo" />
                </div>
            </div>


            <span class='text-line-overflow text-light' style="max-width: 180px">
                {{user?.first_name}}
            </span>
        </a>
        <a class="body-xl pl-4 py-2 account-button row w-100 mx-0 align-items-center" *ngIf="!hasHomeLinks"
            (click)="hasHomeLinks = !hasHomeLinks">
            <span>
                Menu principal
            </span>
        </a>
        <hr class='divider' />
    </span>


    <ng-container>
        <a href="javascript:void(0)" class="ml-4 closebtn" (click)="closeNav()">&times;</a>
        <span *ngIf="hasHomeLinks">
            <a [href]="isYoung ? 'jovem' : ''" class='body-xl pl-4 py-2 d-block' target="_self">
                Início
            </a>
            <a *ngFor="let link of links" class='body-xl pl-4 py-2 d-block' target="_self" href="{{link.url}}">
                {{link.name}}
            </a>
        </span>
        <span *ngIf="isAuthenticated && !hasHomeLinks">
            <a class='body-xl pl-4 py-2 d-block' [href]="isYoung ? 'jovem/dashboard' : 'dashboard' ">
                Sua conta
            </a>
            <a class='body-xl pl-4 py-2 d-block' (click)="logout()">
                Sair
            </a>
        </span>
    </ng-container>
</div>