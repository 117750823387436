<div class="snackbar p-3" *ngIf="isOpen">
    <div class="row w-100 mx-0 align-items-center">
        <ng-content></ng-content>
        <div class="col-12 px-0">
            <div class='row w-100 mx-0'>
                <div class='col-6 pl-0'>
                    <button role="button" class='btn btn-outline-light w-100' (click)="onCancelHandle()">{{cancelLabel}}</button>
                </div>
                <div class='col-6 pr-0'>
                    <button role="button" class='btn btn-light w-100' (click)="onConfirmHandle()">{{confirmLabel}}</button>
                </div>
            </div>
        </div>
    </div>
</div>