<header class='mx-lg-3 mx-0'>
  <nav class="navbar navbar-expand-lg navbar-dark mb-5 justify-content-sm-between pt-3 pb-4">
    <a [href]="'dashboard'">
      <img class="navbar-brand d-lg-block d-md-block" src="/assets/img/brand/logo-caption-black.png" alt="logo" height="95" />
    </a>
    <div class='row align-items-center justify-content-end'>
      <ng-container *ngIf="!isYoung">
        <div class="col-auto px-lg-3 px-md-3 px-2">
          <button (click)="back()" *ngIf="backBtnShow" class="btn btn-lg btn-outline-primary w-100">
            Voltar
          </button>
        </div>
        <div class="col-auto px-lg-3 px-md-3 px-2" *ngIf="!!dashboardBtnShow">
          <button (click)="redirect('dashboard')" class="btn btn-lg btn-primary w-100">
            Dashboard
          </button>
        </div>
      </ng-container>
      <div class="col-auto px-lg-3 px-md-3 px-2">
        <button (click)="logout()" class="btn btn-lg btn-outline-primary w-100">
            Sair
        </button>
      </div>
    </div>
  </nav>
</header>
