<progress-bar *ngIf="loadingService.show" class="progress-bar-top"></progress-bar>
<router-outlet></router-outlet>
<router-outlet name="modal"></router-outlet>
<ngx-snackbar></ngx-snackbar>

<snackbar [isOpen]="isSnackBarOpen" [confirmLabel]="'Aceitar cookies'" [cancelLabel]="'Rejeitar'"
    (onCancel)="acceptCookies(false)"
    (onConfirm)="acceptCookies(true)"
>
    <span class='col-12 px-0 body-sm snackbar'>
        O NAU Match utiliza cookies para garantir que você tenha a melhor experiência em nosso site.
        <p class='body-sm'>
            Para mais informações, acesse nossos
            <a rel="noopener noreferrer nofollow" href="termos-de-uso" target="_blank">
                termos de uso.
            </a>
        </p>
    </span>
</snackbar>

<action-modal
    [isOpen]="isOpen"
    [roundedCircle]="false"
    [iconSource]="'/assets/icons/vacancy/warning-wallet.svg'"
    [title]="'Você não tem acesso a esse conteúdo'"
    [description]="'Para conseguir acessar este link, entre em contato com o suporte'"
    [confirmLabel]="'Entrar em contato'"
    [cancelLabel]="'Fechar'"
    (onClose)="changeCookiesPermission(false, false)"
    (onCancel)="changeCookiesPermission(false, true)"
    (onConfirm)="changeCookiesPermission(true, true)"
></action-modal>
