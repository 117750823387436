import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { MatchEmployee } from '../model/match-employee.class';
import { MatchOwner } from '../model/match-owner';
import { MatchYoung } from '../model/match-young';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { CookieService } from '../services/cookies/cookie.service';
import { UserService } from '../services/user/user.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
      private router: Router,
      private authenticationService: AuthenticationService,
      private cookieService: CookieService,
      private location: Location,
      public userService: UserService,
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {
      const currentUser = this.authenticationService.currentUserValue;
      const token = this.cookieService.get('auth-nau-match-token')
      const tokenParam = route.queryParams.tkn;
      const dataRole = route.data.roles
      const url = this.router.url
      const isYoungRole = (url.match('jovem'))
      if (
        tokenParam
      ) {
        const isLogged: boolean = await this.loginUserWithToken(route, tokenParam);
        if (isLogged) {
          return true;
        }
      }
      if (currentUser) {
        // logged in so return true
        if (dataRole) {
          if (!this.authenticationService.verifyRoles(dataRole)) {
            if (isYoungRole) {
              this.router.navigate(['/jovem/login'])
            } else {
              this.location.back()
            }
            return false;
          }
        }
        return true;
      }

      else if(!currentUser && !token) {
        if(dataRole) {
          this.router.navigate(['login'], { queryParams: { r: state.url } })
        }
        return false
      }
      // not logged in so redirect to login page with the return url
      if (isYoungRole) {
        this.router.navigate(['/jovem/login'])
      } else {
        this.location.back()
      }
      return false;
    }

    /**
     * Valida confirmação de e-mail (se existir)
     * @param route ActivatedRouteSnapshot
     * @returns Promise<boolean>
     */
    async loginUserWithToken(route: ActivatedRouteSnapshot, tokenParam: string): Promise<any> {
      try {
        const confirmEmail = route.queryParams.confirmEmail;
        let user: MatchEmployee | MatchOwner | MatchYoung;
        
        if(tokenParam) {
          user = (await this.userService.getUserDetail(tokenParam, confirmEmail).toPromise()).user;
          if(user) {
            this.authenticationService.updateUser(user);
            return true;
          }
        }
        
      } catch (error) {
        return false;
      }
    }

}
