import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpreadModalComponent } from './spread-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    SpreadModalComponent
  ],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
  ],
  exports: [SpreadModalComponent]
})
export class SpreadModalModule { }
