
<form [formGroup]="form" class='mb-4' (submit)="onSubmitHandle()">
    <ng-container [ngSwitch]="whichStep">
        <ng-container *ngSwitchCase="'register'">
            <h1 class="display-4 mb-5">Crie sua conta NAU Match</h1>
            <app-young-register (eventSendResult)="registerResult($event)"></app-young-register>
        </ng-container>
        <ng-container  *ngSwitchCase="'login'">
            <h1 class='display-4'>Faça o login!</h1>
            <label class='body-md bold'>Email</label>
            <input
                class='form-control form-control-lg mb-2'
                formControlName="email"
                type='email'
                placeholder="Email"
                [ngClass]="{ 'is-invalid': f.email.touched && f.email.errors }"
            />
            <app-error [showError]="emailError" [error]="'E-mail está errado ou não existe'" [customClass]="'mb-0'"></app-error>
            <label class='body-md bold'>Senha</label>
            <div class="form-password-box">
                <input
                    class='form-control form-control-lg mb-2'
                    [type]="isPasswordVisible ? 'text' : 'password' "
                    placeholder="Senha"
                    formControlName="password"
                    [ngClass]="{ 'is-invalid': f.password.touched && f.password.errors }"
                />
                <span class="material-icons" *ngIf="!isPasswordVisible" (click)="isPasswordVisible = !isPasswordVisible">visibility_off</span>
                <span class="material-icons" *ngIf="isPasswordVisible" (click)="isPasswordVisible = !isPasswordVisible">visibility</span>
            </div>
            <app-error [showError]="passwordError" [error]="'Senha incorreta'" [customClass]="'mb-0'"></app-error>
            <a 
                class='btn btn-link text-right w-100 px-0'
                (click)="onChangeStep('forgot')"
            >
                Esqueci minha senha
            </a>
            <p *ngIf="!!confirmEmailError" class='error body-md-2 mb-0'>
                Seu email não foi confirmado, clique 
                <a (click)="onSendNewConfirmEmail()">aqui</a> 
                para reenviar o email de confirmação
            </p>
        </ng-container>
        <ng-container  *ngSwitchCase="'forgot'">
            <h1 class='display-4'>Digite seu e-mail</h1>
            <label class='body-md bold'>Email</label>
            <input
                class='form-control mb-2'
                formControlName="email"
                type='email'
                placeholder="Email cadastrado"
                [ngClass]="{ 'is-invalid': f.email.touched && f.email.errors }"
            />
        </ng-container>
        <ng-container *ngSwitchCase="'forgotForm'">
            <h1 class='display-4'>Crie uma nova senha</h1>
            <forgot-password-form [email]="email" [token]="token" (onRequestEnd)="onChangeStep('close')"></forgot-password-form>
        </ng-container>
    </ng-container>
    <button *ngIf="whichStep !== 'register' && whichStep !=='forgotForm' && whichStep !=='extra-step'" type="submit" 
        class='btn btn-primary btn-lg float-right mt-3'
    >
        Enviar
    </button>
</form>
