<ng-container [ngSwitch]="iconType">

    <img *ngSwitchCase="'arrow'"
        class="icon"
        (click)="onIconClick()"
        [ngClass]="{
            'icon-upside' : isUpside
        }"
        width="20px"
        src="../../../../assets/icons/arrowdown.svg"
        popover="{{popoverText}}"
        triggers="{{triggerType}}"
    />

    <img *ngSwitchCase="'chevron'"
        class="icon"
        width="14px"
        (click)="onIconClick()"
        src="../../../../assets/icons/chevronDown.svg"
        [ngClass]="{
            'icon-upside' : isUpside
        }"
        popover="{{popoverText}}"
        triggers="{{triggerType}}"
    />
</ng-container>
